<template>
  <ItemWrapper>
    <template v-if="!pending.ticketPresets && !pending.getEditTicket">
      <ValidationObserver ref="observer">
        <v-form autocomplete="off" @submit.prevent="submitForm">
          <v-row align="stretch">
            <v-col cols="12" md="4">
              <AppCard class="h-100" :title="$t('Customer')">
                <v-col cols="12">
                  <SearchSelectHandle
                    v-model="handle_id"
                    rules="required"
                    handle-type="Owner"
                    :handle="ticket.handle"
                    :persistent-placeholder="!isCreateMode"
                    @onClickCreateNewHandle="showCreateHandleDialog('handle_id')"
                  />
                </v-col>
                <v-col v-if="clientData && clientData.notes" cols="12">
                  <h3>Client Notes</h3>

                  <v-expansion-panels :value="0">
                    <template v-for="note in clientData.notes">
                      <v-expansion-panel :key="`note-${note.id}`">
                        <v-expansion-panel-header>
                          {{ note.name }}
                          <v-chip x-small class="flex-grow-0 ml-4" color="primary">
                            {{ note.type }}
                          </v-chip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <p>{{ note.note }}</p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </template>
                  </v-expansion-panels>
                </v-col>
              </AppCard>
            </v-col>

            <v-col cols="12" md="4">
              <AppCard class="h-100" :title="$t('Customer Tickets')">
                <v-list v-if="clientData && clientData.client_tickets" dense>
                  <template v-for="clientTicket in clientData.client_tickets">
                    <v-list-item v-if="clientTicket.id !== ticket.id" :key="`client-ticket-${clientTicket.id}`">
                      <div class="d-flex justify-space-between w-100">
                        <span>{{ clientTicket.subject }}</span>
                        <v-btn :to="{ name: 'tickets-item', params: { id: clientTicket.id } }" icon>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item>
                  </template>
                </v-list>
              </AppCard>
            </v-col>

            <v-col cols="12" md="4">
              <AppCard class="h-100" :title="$t('SLA')">
                <p v-if="clientData && clientData.sla">Reaktionszeit: {{ clientData.sla.first_response }}</p>
                <p v-if="clientData && clientData.sla">Späteste Antwort am: {{ clientData.sla.response_date }}</p>
              </AppCard>
            </v-col>
          </v-row>

          <v-row>
            <v-col order="2" order-lg="1" cols="12" lg="8">
              <AppCard :title="$t(isCreateMode ? 'Create Ticket' : 'Edit Ticket')" class="fill-height">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <VTextFieldWithValidation
                        v-model="subject"
                        label="Subject"
                        name="subject"
                        rules="required"
                        placeholder="Enter Subject"
                      />
                    </v-col>

                    <v-col cols="12">
                      <VTextareaWithValidation
                        v-model="message"
                        label="Intern Message"
                        name="internmessage"
                        placeholder="Enter Intern Message"
                      />
                    </v-col>

                    <v-col cols="12">
                      <VTextareaWithValidation
                        v-model="summary_message"
                        label="Summary Message"
                        name="summarymessage"
                        rows="4"
                        placeholder="Enter Summary Message"
                      />
                    </v-col>

                    <v-col cols="12" class="text-right">
                      <v-btn
                        type="submit"
                        class="mx-0 font-weight-light"
                        color="success"
                        :disabled="pending.createTicket || pending.editTicket"
                      >
                        Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </AppCard>
            </v-col>

            <v-col order="1" order-lg="2" cols="12" lg="4">
              <v-row>
                <v-col cols="12">
                  <AppCard :title="$t('Ticket Data')">
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <VSelectWithValidation
                            v-model="type"
                            rules="required"
                            label="Type"
                            :items="typesArray"
                            :disabled="pending.ticketPresets"
                            menu-props="offset-y"
                            placeholder="Select Type"
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-divider />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <DatePickerWithValidation
                            v-model="duedate"
                            label="Due Date"
                            name="duedate"
                            rules="required"
                            placeholder="Enter Due Date"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <VSelectWithValidation
                            v-model="status"
                            rules="required"
                            label="Status"
                            :items="statusArray"
                            :disabled="pending.ticketPresets"
                            menu-props="offset-y"
                            placeholder="Select Status"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <VSelectWithValidation
                            v-model="priority"
                            rules="required"
                            label="Priority"
                            :items="prioritiesArray"
                            :disabled="pending.ticketPresets"
                            menu-props="offset-y"
                            placeholder="Select Priority"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <VSelectWithValidation
                            v-model="ticket_queue_id"
                            rules="required"
                            label="Ticket Queue"
                            :items="ticketQueuesArray"
                            :disabled="pending.ticketPresets"
                            menu-props="offset-y"
                            placeholder="Select Ticket Queue"
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-divider />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <VSelectWithValidation
                            v-model="user_id"
                            rules="required"
                            label="User"
                            :items="usersArray"
                            menu-props="offset-y"
                            placeholder="Select User"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <DatePickerWithValidation
                            v-model="waituntil"
                            label="Wait until"
                            name="waituntil"
                            placeholder="Enter Wait until"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </AppCard>
                </v-col>

                <v-col cols="12">
                  <AppCard :title="$t('Customer mood')">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <SelectMood v-model="mood" rules="required" label="Kundenstimmung" hide-label />
                        </v-col>
                      </v-row>
                    </v-container>
                  </AppCard>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <AppCard :title="$t('Tasks')" title-actions>
                <template #titleActions>
                  <v-btn color="secondary" fab small dark @click="onAddTicketTask">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-expansion-panels v-model="activeTaskPanel" focusable multiple>
                        <TicketTaskRow
                          v-for="(ticketTask, i) in ticket.ticket_tasks"
                          :key="i"
                          :index="i"
                          :users="usersArray"
                          :ticket-task="ticketTask"
                          @removeTask="removeTicketTask"
                          @updateTicketTask="updateTicketTask"
                        />
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-container>
              </AppCard>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </template>

    <v-row v-else>
      <v-col cols="12" class="text-center">
        <v-card class="py-4 px-5">
          <v-progress-circular indeterminate color="primary" />
        </v-card>
      </v-col>
    </v-row>

    <CreateShortHandleDialog v-model="createNewHandleDialog" @onHandleCreated="onHandleCreated" />
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref, watch } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import { parseObjectToArray } from '@/helpers'
import useCreateTicket from '@/composables/tickets/useCreateTicket'
import useEditTicket from '@/composables/tickets/useEditTicket'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation'
import SearchSelectHandle from '@/components/Handles/SearchSelectHandle'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VTextareaWithValidation from '@/components/inputs/VTextareaWithValidation'
import CreateShortHandleDialog from '@/components/Handles/CreateShortHandleDialog'
import SelectMood from '@/components/UI/SelectMood'
import TicketTaskRow from '@/components/Tickets/TicketTaskRow'

export default defineComponent({
  name: 'TicketItemTemplate',
  components: {
    AppCard,
    ItemWrapper,
    SearchSelectHandle,
    DatePickerWithValidation,
    VSelectWithValidation,
    VTextFieldWithValidation,
    VTextareaWithValidation,
    CreateShortHandleDialog,
    SelectMood,
    TicketTaskRow,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router, $route }, root }) {
    const prevRoute = ref('/Tickets')
    const createNewHandleDialog = ref(null)
    const activeTaskPanel = ref([])
    const observer = ref(null)
    const activeRoute = computed(_ => root.$route)

    const isCreateMode = computed(_ => $route.name === 'tickets-create')

    // store
    const pending = computed(_ => $store.state.ticket.pending)
    const ticketPresets = computed(_ => $store.state.ticket.ticketPresets)
    const ticket = computed(_ => $store.state.ticket.ticket)
    const clientData = computed(_ => $store.state.ticket.clientData)
    const errors = computed(_ => $store.state.ticket.errors)
    const getTicketPresets = _ => $store.dispatch('ticket/getTicketPresets')
    const getClientData = clientId => $store.dispatch('ticket/getClientData', clientId)
    const clearTicket = _ => $store.dispatch('ticket/clearTicket')
    // const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)
    const addTicketTask = _ => $store.commit('ticket/ADD_TICKET_TASK')
    const removeTicketTask = taskIndex => $store.commit('ticket/REMOVE_TICKET_TASK', taskIndex)
    const updateTicketTask = updatedTicketTask => $store.commit('ticket/UPDATE_TICKET_TASK', updatedTicketTask)
    const addSearchHandle = handle => $store.commit('handle/ADD_HANDLE_TO_SEARCH', handle)
    const getEditTicket = _ => $store.dispatch('ticket/getEditTicket')
    const ticketMapFields = mapFields('ticket', [
      'ticket.subject',
      'ticket.type',
      'ticket.priority',
      'ticket.message',
      'ticket.summary_message',
      'ticket.duedate',
      'ticket.waituntil',
      'ticket.status',
      'ticket.ticket_queue_id',
      'ticket.handle_id',
      'ticket.mood',
      'ticket.user_id',
    ])

    const { createTicket } = useCreateTicket({ pending, $store, $router })
    const { editTicket } = useEditTicket({ pending, $store, $router })

    const typesArray = computed(_ => parseObjectToArray(ticketPresets.value.type))
    const statusArray = computed(_ => parseObjectToArray(ticketPresets.value.status))
    const prioritiesArray = computed(_ => parseObjectToArray(ticketPresets.value.priority))
    const usersArray = computed(_ => parseObjectToArray(ticketPresets.value.users))
    const ticketQueuesArray = computed(_ => parseObjectToArray(ticketPresets.value.ticketQueues))

    if (isCreateMode.value) {
      getTicketPresets().then(_ => {
        ticketMapFields.type.value = typesArray.value[0]?.value
        ticketMapFields.status.value = statusArray.value[0]?.value
        ticketMapFields.priority.value = prioritiesArray.value[0]?.value
        ticketMapFields.ticket_queue_id.value = ticketQueuesArray.value[0]?.value
        ticketMapFields.user_id.value = usersArray.value[0]?.value
        ticketMapFields.mood.value = 0
      })
    } else {
      getEditTicket()
    }

    watch(ticketMapFields.handle_id, id => {
      if (id) {
        getClientData(id).then(_ => {
          if (isCreateMode.value) {
            ticketMapFields.duedate.value = clientData.value.sla.duedate
          }
        })
      }
    })

    watch(activeRoute, routeVal => {
      if (ticket.value.id !== routeVal.params.id) {
        clearTicket()
        getEditTicket()
      }
    })

    const submitForm = async _ => {
      const isValid = await observer.value.validate()
      if (!isValid) {
        const ticketTaskErrorFieldKeys = Object.keys(observer.value.errors).filter(err => err.includes('ticket-task'))

        if (ticketTaskErrorFieldKeys.length >= 1) {
          const ticketTaskKeyNumbers = ticketTaskErrorFieldKeys.map(key => {
            const regex = /(\d+)(?!.*\d)/gm
            return key.match(regex)[0]
          })

          const ticketTaskIndexes = [...new Set(ticketTaskKeyNumbers)]
          activeTaskPanel.value = [...ticketTaskIndexes.map(i => parseInt(i))]
        }

        return false
      }

      if (isCreateMode.value) {
        createTicket()
      } else {
        editTicket()
      }
    }

    const showCreateHandleDialog = type => {
      createNewHandleDialog.value = type
    }

    const onHandleCreated = handle => {
      ticketMapFields[createNewHandleDialog.value].value = handle.id
      addSearchHandle(handle)
      createNewHandleDialog.value = null
    }

    const onAddTicketTask = _ => {
      addTicketTask()
      activeTaskPanel.value = [ticket.value.ticket_tasks.length - 1]
    }

    onUnmounted(_ => {
      clearTicket()
    })

    return {
      activeTaskPanel,
      createNewHandleDialog,
      pending,
      errors,
      prevRoute,
      typesArray,
      statusArray,
      prioritiesArray,
      usersArray,
      ticket,
      ticketQueuesArray,
      ticketPresets,
      showCreateHandleDialog,
      onAddTicketTask,
      removeTicketTask,
      submitForm,
      onHandleCreated,
      isCreateMode,
      clientData,
      observer,
      updateTicketTask,
      ...ticketMapFields,
    }
  },
}) //
</script>
