import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'

export default ({ pending, $store, $router }) => {
  const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
  const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

  const storeCreateTicket = _ => $store.dispatch('ticket/createTicket')

  const createTicket = _ => {
    if (pending.value.createTicket) {
      return
    }

    if (redirectDirect.value) {
      setForceRedirect(true)
    }

    storeCreateTicket().then(({ ticket, errors }) => {
      if (errors) {
        setForceRedirect(false)
        addToast({
          msg: 'Erstellung fehlgeschlagen',
          type: 'error',
        })
        return
      }

      addToast({
        msg: 'Ticket erfolgreich erstellt',
        type: 'success',
      })

      if (redirectDirect.value) {
        $router.push({
          name: 'tickets-item',
          params: { id: ticket.id },
        })
      } else {
        $router.push({ path: '/Tickets' })
      }
    })
  }

  return {
    createTicket,
  }
}
