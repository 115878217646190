<template>
  <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules">
    <v-radio-group
      v-model="computedValue"
      slot-scope="{ errors }"
      :error-messages="errors"
      v-bind="$attrs"
      hide-details="auto"
      label=""
      class="mt-0"
      v-on="$listeners"
    >
      <v-radio v-for="(radioInput, index) in radioInputs" :key="index" v-bind="radioInput" />
    </v-radio-group>
  </ValidationProvider>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'VRadiosWithValidation',
  props: {
    rules: {
      type: [
        Object,
        String,
      ],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
    radioInputs: {
      type: Array,
      default: _ => [],
    },
  },
  setup(props, { emit }) {
    const computedValue = computed({
      set: val => {
        emit('input', val)
      },
      get: _ => props.value,
    })

    if (props.value) {
      computedValue.value = props.value
    }

    return {
      computedValue,
    }
  },
})
</script>
