<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div>
        <div class="d-flex align-center">
          <v-icon v-if="task.accounting" class="mr-1">mdi-currency-usd</v-icon>
          <span>{{ $moment(date).format('DD.MM.YYYY') }}</span>
          <div v-if="computedFromTime && computedToTime" class="ml-2 d-inline-flex flex-column">
            <small class="mb-1">From: {{ computedFromTime }}</small>
            <small>To: {{ computedToTime }}</small>
          </div>
        </div>

        <div v-if="task.user_id" class="mt-2">
          <b>User:</b>
          {{ users[task.user_id] }}
        </div>
      </div>

      <v-spacer />

      <div class="actions text-right">
        <v-tooltip v-if="isTaskDisabled" top>
          <template #activator="{ on }">
            <v-btn color="info" icon v-on="on">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>Task wurde bereits abgerechnet</span>
        </v-tooltip>

        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              :disabled="isTaskDisabled"
              class="remove-button"
              color="error"
              icon
              v-on="on"
              @click.stop="removeTask"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <span>Remove Task</span>
        </v-tooltip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container>
        <v-row class="ticket-task--row" wrap>
          <v-col cols="12" sm="6">
            <VTextareaWithValidation
              v-model="task.message"
              label="Message"
              name="message"
              :vid="`ticket-task-message-${index}`"
              rules="required"
              placeholder="Enter Message"
              :disabled="isTaskDisabled"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12">
                <VSelectWithValidation
                  v-model="task.user_id"
                  rules="required"
                  label="User"
                  :items="users"
                  menu-props="offset-y"
                  :vid="`ticket-task-user-${index}`"
                  placeholder="Select User"
                  :disabled="isTaskDisabled"
                />
              </v-col>

              <v-col cols="12">
                <DatePickerWithValidation
                  v-model="date"
                  label="Date"
                  name="date"
                  rules="required"
                  :vid="`ticket-task-date-${index}`"
                  placeholder="Enter Date"
                  :disabled="isTaskDisabled"
                />
              </v-col>

              <v-col cols="12">
                <VTextFieldWithValidation
                  v-model="computedFromTime"
                  label="From"
                  name="fromTime"
                  rules="required"
                  :vid="`ticket-task-fromTime-${index}`"
                  type="time"
                  placeholder="Enter From Time"
                  :disabled="isTaskDisabled"
                />
              </v-col>

              <v-col cols="12">
                <VTextFieldWithValidation
                  v-model="computedToTime"
                  label="To"
                  name="toTime"
                  :rules="{ required: true, timeMin: computedFromTime }"
                  :vid="`ticket-task-toTime-${index}`"
                  type="time"
                  placeholder="Enter To Time"
                  :disabled="isTaskDisabled"
                />
              </v-col>

              <v-col cols="12">
                <VCheckboxWithValidation
                  v-model="task.approach"
                  label="Approach"
                  name="approach"
                  :vid="`ticket-task-approach-${index}`"
                  :disabled="isTaskDisabled"
                />

                <VCheckboxWithValidation
                  v-model="task.accounting"
                  label="Accounting"
                  name="accounting"
                  :vid="`ticket-task-accounting-${index}`"
                  :disabled="isTaskDisabled"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'

import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'
import VTextareaWithValidation from '@/components/inputs/VTextareaWithValidation'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation'
import VCheckboxWithValidation from '../inputs/VCheckboxWithValidation'

export default defineComponent({
  name: 'TicketTaskRow',
  components: {
    VTextFieldWithValidation,
    DatePickerWithValidation,
    VSelectWithValidation,
    VTextareaWithValidation,
    VCheckboxWithValidation,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    ticketTask: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit, root: { $nextTick, $moment } }) {
    const date = ref(null)
    const fromTime = ref(null)
    const toTime = ref(null)
    const delayTimer = ref(null)
    const taskData = reactive({ task: {} })

    if (props.ticketTask) {
      taskData.task = ref({ ...props.ticketTask })
    }

    watch(taskData, newVal => {
      clearTimeout(delayTimer.value)
      delayTimer.value = setTimeout(() => {
        emit('updateTicketTask', { index: props.index, task: newVal.task })
      }, 200)
    })

    watch(
      _ => props.ticketTask,
      newVal => (taskData.task = { ...newVal }),
    )

    const isTaskDisabled = computed(_ => props.ticketTask?.ticket_summary_id !== null)

    const computedFromTime = computed({
      set: val => {
        taskData.task.from_date = `${date.value} ${val}`
        fromTime.value = val
      },
      get: _ => fromTime.value,
    })

    const computedToTime = computed({
      set: val => {
        taskData.task.to_date = `${date.value} ${val}`
        toTime.value = val
      },
      get: _ => toTime.value,
    })

    watch(date, val => {
      taskData.task.from_date = date.value && fromTime.value ? `${date.value} ${fromTime.value}` : null
      taskData.task.to_date = date.value && toTime.value ? `${date.value} ${toTime.value}` : null
    })

    if (props.ticketTask.from_date) {
      date.value = $moment(props.ticketTask.from_date).format('YYYY-MM-DD')
      computedFromTime.value = $moment(props.ticketTask.from_date).format('HH:mm')
      if (props.ticketTask.to_date) {
        computedToTime.value = $moment(props.ticketTask.to_date).format('HH:mm')
      }
    } else {
      date.value = $moment().format('YYYY-MM-DD')
    }

    const removeTask = _ => {
      emit('removeTask', props.index)
    }

    return {
      date,
      computedFromTime,
      computedToTime,
      removeTask,
      isTaskDisabled,
      task: taskData.task,
    }
  },
}) //
</script>
