<template>
  <VRadiosWithValidation v-model="computedValue" row :radio-inputs="radioInputs" v-bind="$attrs" />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

import VRadiosWithValidation from '@/components/inputs/VRadiosWithValidation'

export default defineComponent({
  name: 'SelectMood',
  components: {
    VRadiosWithValidation,
  },
  props: {
    value: {
      type: null,
      default: null,
    },
  },
  setup(props, { emit }) {
    const radioInputs = [
      {
        value: 0,
        offIcon: 'mdi-checkbox-blank-circle-outline',
        onIcon: 'mdi-checkbox-blank-circle',
        color: 'grey',
        large: true,
      },
      {
        value: 1,
        offIcon: 'mdi-emoticon-outline',
        onIcon: 'mdi-emoticon',
        color: 'success',
      },
      {
        value: 2,
        offIcon: 'mdi-emoticon-neutral-outline',
        onIcon: 'mdi-emoticon-neutral',
        color: 'warning',
      },
      {
        value: 3,
        offIcon: 'mdi-emoticon-sad-outline',
        onIcon: 'mdi-emoticon-sad',
        color: 'error',
      },
    ]

    const computedValue = computed({
      set: val => {
        emit('input', val)
      },
      get: _ => props.value,
    })

    return {
      computedValue,
      radioInputs,
    }
  },
})
</script>

<style lang="scss" scoped>
$size: 50px;
$margin: 7px;
::v-deep .v-input--radio-group {
  .v-radio {
    margin-right: 12px;
    .v-input--selection-controls__input {
      height: $size;
      width: $size;
      input {
        max-height: $size;
      }
      .v-icon {
        font-size: $size;
      }
      .v-input--selection-controls__ripple {
        height: calc(#{$size} + 2px);
        width: calc(#{$size} + 2px);
        top: calc(50% - #{(($size + 2 * $margin + 2) / 2)});
        left: -$margin - 1;
      }
    }
  }
}
</style>
